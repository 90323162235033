@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');


body {
  margin: 0;
  font-family: "Roboto" ,-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html{
  scroll-behavior: smooth;
  scroll-padding-top: 70px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


*{
  box-sizing: border-box;
}

.flag-discount {
  border-radius: 6px 0 0 6px;
  color: #fff;
  display: block;
  float: left;
  padding: 10px 20px;
  background: #b11926;
  font-size: 20px;
  font-weight: 400;
  position: relative;
}
.flag-discount::before,
.flag-discount::after {
  content: "";
  position: absolute;
  left: 100%;
  width: 0;
  height: 0;
  border-style: solid;
  display: block;
}
.flag-discount::before {
  top: 0;
  border-width: 22px 22px 0 0;
  border-color: #b11926 transparent transparent transparent;
}
.flag-discount::after {
  bottom: 0;
  border-width: 0 22px 22px 0;
  border-color: transparent transparent #b11926 transparent;
}